import Vue from 'vue'
// axios
import axios from 'axios'
// import jwt from '@core/auth/jwt/useJwt'
// import { logout } from '@/auth/utils'
import { initialAbility } from '@/libs/acl/config'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.response.use(response => {
  if (response.status === 200 && response.data.message) {
    // console.log('200')
    // toast.success(response.data.message)
  }
  if (response.status === 201 && response.data.message) {
    // console.log('201')
    // toast.success(response.data.message)
  }
  return response
}, error => {
  // Do something with response error
  // check for errorHandle config

  // if has response show the error
  if (error.response) {
    if (error.response.status === 404 || error.response.status === 400) {
      // console.log('400')
      // toast.error(error.response.data.message)
    }
    if (error.response.status === 401) {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    }
  }
  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
