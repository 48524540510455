import Vue from 'vue'
import axios from 'axios'

export default {
  state: {
    tickets: [],
    lessons: [],
  },
  getters: {},
  mutations: {
    TICKETS_SUCCESS: (state, data) => {
      Vue.set(state, 'tickets', data)
      // ? console.log(JSON.stringify(data))
    },
    LESSONS_SUCCESS: (state, data) => {
      Vue.set(state, 'lessons', data)
      // ? console.log(JSON.stringify(data))
    },
  },
  actions: {
    refreshTickets() {
      axios.get('/api/lessons/tickets')
        .then(response => {
          // ? console.log(JSON.stringify(response.data))
          this.commit('TICKETS_SUCCESS', response.data)
        })
        .catch(ex => {
          alert(`Failed to load tickets: ${ex}`)
        })
    },
    refreshLessons(store, ticketId) {
      // ? console.log(JSON.stringify(ticketId))
      const query = `/api/lessons/lessons_by_ticket?ticket_id=${ticketId}`
      axios.get(query)
        .then(response => {
          // ? console.log(JSON.stringify(response.data))
          this.commit('LESSONS_SUCCESS', response.data)
        })
        .catch(ex => {
          alert(`Failed to load lessons: ${ex}`)
        })
    },
  },
}
