// ? import Vue from "vue";
// ? import axios from 'axios';

export default {
  state: {
    // ? rating
  },
  getters: {},
  mutations: {},
  actions: {},
}
