export default {
  // Endpoints
  loginEndpoint: '/api/authenticate/login',
  authEndpoint: '/api/authenticate/auth',
  registerEndpoint: '/api/authenticate/register',
  refreshEndpoint: '/api/authenticate/refresh_token',
  logoutEndpoint: '/api/authenticate/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
